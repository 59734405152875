@mixin root-colors {
  --main-1: #00adee;
  --main-2: #ff1c44;
  --main-3: #ffa726;
  --main-4: #30b667;
  --main-5: #ffe200;
  --main-6: #ffa726;
  --sub-1: #fff; //9
  --sub-2: #fafafa; //1
  --sub-3: #ededed; //2
  --sub-4: #d0d0d0; //3
  --sub-5: #919191; //8
  --sub-6: #666; //4
  --sub-7: #2d313b; //5
  --sub-8: #333; //6
  --sub-9: #000; //7
  --sub-10: #666; //8

  --other-1: #ebf9fe; //10
  --other-2: #8892a0; //11
}
