@import 'root-variables/font-sizes';
@import 'root-variables/spacings';
@import 'root-variables/colors';

:root {
  @include root-font-sizes;
  @include root-spacing-sizes;

  @include root-colors;
}

body,
html {
  font-family: 'Open Sans', sans-serif !important;
  padding: 0;
  margin: 0;
}

* {
  &,
  &:before,
  &:after {
    outline: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

h1,
.text-xxl {
  font-size: var(--text-xxl, 2.074em);
}

h2,
.text-xl {
  font-size: var(--text-xl, 1.728em);
}

h3,
.text-lg {
  font-size: var(--text-lg, 1.44em);
}

h4,
.text-md {
  font-size: var(--text-md, 1.2em);
}

.text-base {
  font-size: 1em;
}

small,
.text-sm {
  font-size: var(--text-sm, 0.833em);
}

.text-xs {
  font-size: var(--text-xs, 0.694em);
}

.margin-v-xs {
  margin-top: var(--space-xs);
  margin-bottom: var(--space-xs);
}

.margin-h-xs {
  margin-left: var(--space-xs);
  margin-right: var(--space-xs);
}

.margin-v-sm {
  margin-top: var(--space-sm);
  margin-bottom: var(--space-sm);
}

.margin-h-sm {
  margin-left: var(--space-sm);
  margin-right: var(--space-sm);
}

.margin-v-md {
  margin-top: var(--space-md);
  margin-bottom: var(--space-md);
}

.margin-h-md {
  margin-left: var(--space-md);
  margin-right: var(--space-md);
}

.margin-v-lg {
  margin-top: var(--space-lg);
  margin-bottom: var(--space-lg);
}

.margin-h-lg {
  margin-left: var(--space-lg);
  margin-right: var(--space-xl);
}

.margin-v-xl {
  margin-top: var(--space-xl);
  margin-bottom: var(--space-xl);
}

.margin-h-xl {
  margin-left: var(--space-xl);
  margin-right: var(--space-xl);
}

.padding-v-xs {
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
}

.padding-h-xs {
  padding-left: var(--space-xs);
  padding-right: var(--space-xs);
}

.padding-v-sm {
  padding-top: var(--space-sm);
  padding-bottom: var(--space-sm);
}

.padding-h-sm {
  padding-left: var(--space-sm);
  padding-right: var(--space-sm);
}

.padding-v-md {
  padding-top: var(--space-md);
  padding-bottom: var(--space-md);
}

.padding-h-md {
  padding-left: var(--space-md);
  padding-right: var(--space-md);
}

.padding-v-lg {
  padding-top: var(--space-lg);
  padding-bottom: var(--space-lg);
}

.padding-h-lg {
  padding-left: var(--space-lg);
  padding-right: var(--space-xl);
}

.padding-v-xl {
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);
}

.padding-h-xl {
  padding-left: var(--space-xl);
  padding-right: var(--space-xl);
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-right {
  padding-right: 0;
}

.no-padding-top {
  padding-top: 0;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.no-margin-left {
  margin-left: 0;
}

.no-margin-right {
  margin-right: 0;
}

.no-margin-top {
  margin-top: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.back-next-component {
  display: flex;
  justify-content: space-between;
}

.icon-group {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .top-level {
    position: absolute;
    z-index: 2;
    color: white;
    display: flex;
    font-size: 0.8em;
  }

  .bottom-level {
    position: absolute;
    z-index: 1;
    font-size: 2em;
    display: flex;
    color: #00adee87;
  }
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .round-image {
      border-radius: 100%;
      display: block;
      height: 150px;
      width: 150px;
    }
  }
}

.grid-container {
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
}

.image-box {
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  align-items: end;
  .image-box__label {
    font-weight: 600;
    color: var(--sub-5);
    border-bottom: 2px solid var(--sub-3);
  }
  .dynamic-image {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--sub-5);

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &:first-child {
      margin: 0;
    }

    .dynamic-image__img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      z-index: 0;
    }
  }
}
