.make-icomoon-font-face(@data-uri) {
  @font-face {
    font-family: 'icomoon';
    src: @data-uri format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
}

.make-icomoon-fonts(@css:{}) {
  [class^='icon-'],
  [class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */

    &:before {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    & > [class*='path']:before {
      font-family: 'icomoon' !important;
    }
  }

  @css();
}

.make-icomoon-icons() {
  .icon-motorcycle:before {
    content: '\e929';
  }

  .icon-refresh:before {
    content: '\e928';
  }

  .icon-license-back:before {
    content: '\e927';
  }

  .icon-license-front:before {
    content: '\e926';
  }

  .icon-vehicle-chassis:before {
    content: '\e922';
  }

  .icon-vehicle-interior:before {
    content: '\e923';
  }

  .icon-vehicle-wheel:before {
    content: '\e924';
  }

  .icon-vehicle-top:before {
    content: '\e925';
  }

  .icon-outline:before {
    content: '\e921';
  }

  .icon-arrow-down-2:before {
    content: '\e903';
  }

  .icon-self-inspection:before {
    content: '\e920';
  }

  .icon-scan-check:before {
    content: '\e94a';
  }

  .icon-vehicle-left:before {
    content: '\e945';
  }

  .icon-vehicle-right:before {
    content: '\e946';
  }

  .icon-vehicle-back:before {
    content: '\e947';
  }

  .icon-vehicle-front:before {
    content: '\e952';
  }

  .icon-accessories:before {
    content: '\e900';
  }

  .icon-add:before {
    content: '\e901';
  }

  .icon-arrow-down:before {
    content: '\e902';
  }

  .icon-arrow-left:before {
    content: '\e904';
  }

  .icon-arrow-right:before {
    content: '\e905';
  }

  .icon-arrow-up:before {
    content: '\e906';
  }

  .icon-background:before {
    content: '\e907';
  }

  .icon-bell:before {
    content: '\e908';
  }

  .icon-camera:before {
    content: '\e909';
  }

  .icon-camera-flash:before {
    content: '\e90a';
  }

  .icon-camera-no-flash:before {
    content: '\e90b';
  }

  .icon-check:before {
    content: '\e90c';
  }

  .icon-check-2:before {
    content: '\e90d';
  }

  .icon-claims:before {
    content: '\e90e';
  }

  .icon-close:before {
    content: '\e90f';
  }

  .icon-damage:before {
    content: '\e910';
  }

  .icon-dashboard:before {
    content: '\e911';
  }

  .icon-delete:before {
    content: '\e912';
  }

  .icon-documents:before {
    content: '\e913';
  }

  .icon-edit:before {
    content: '\e914';
  }

  .icon-envelope:before {
    content: '\e915';
  }

  .icon-error:before {
    content: '\e916';
  }

  .icon-folder:before {
    content: '\e917';
  }

  .icon-insured:before {
    content: '\e918';
  }

  .icon-license-card:before {
    content: '\e919';
  }

  .icon-lock:before {
    content: '\e91a';
  }

  .icon-logout:before {
    content: '\e91b';
  }

  .icon-odometer:before {
    content: '\e91c';
  }

  .icon-policies:before {
    content: '\e91d';
  }

  .icon-profile:before {
    content: '\e91e';
  }

  .icon-icon-question-mark:before {
    content: '\e91f';
  }

  .icon-stack:before {
    content: '\e944';
  }

  .icon-vehicle-license-disk:before {
    content: '\e961';
  }

  .icon-windscreen:before {
    content: '\e96c';
  }
}
